import React from 'react';
import FrontDeskJPG from '@assets/images/front-desk.jpg';
import FrontDeskMobileJPG from '@assets/images/front-desk-mobile.jpg';
// eslint-disable-next-line import/no-cycle
import PricesMain from '~/components/organisms/PricesMain/PricesMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const PricesPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={FrontDeskJPG}
        mobileHero={FrontDeskMobileJPG}
        altHero="Sportraum Arabellapark"
        filter={true}
      >
        <PricesMain />
      </HeaderFooterLayout>
    </>
  );
};

export default PricesPage;