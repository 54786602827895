/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import { Container, ContainerSmall } from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/impressum';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/imprint';

const PricesMain: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <Container>
        <ContainerSmall>
            <div
              dangerouslySetInnerHTML={{
                __html: t('PricesContent1') || tEn('PricesContent1'),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('PricesContent2') || tEn('PricesContent2'),
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t('PricesContent3') || tEn('PricesContent3'),
              }}
            />
        </ContainerSmall>
      </Container>
    </>
  );
};

const ContainerSmallOverwritten = styled.div`
  p {
    font-size: var(--primary-font-size);
    line-height: 1;
  }
`;

export default PricesMain;
